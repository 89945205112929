<template>
  <div v-if="userProfile">
    <div class="slice_up primary" style="margin-top:0px; text-align:left;" v-if="userProfile">
      <v-container>
        <v-avatar style="position:absolute; top:55px;" color="white">
          <img :src="userProfile.photoURL" :alt="userProfile.displayName" />
        </v-avatar>
      </v-container>
    </div>
    <v-container v-if="userProfile">
      <v-row>
        <v-col>
          <h1>{{ userProfile.displayName }}</h1>
          <br />
          <div v-if="progress < 100">
            <p>Ton profil est rempli à {{ progress }} %</p>
            <v-progress-linear v-model="progress" color="primary" height="10" reactive />
          </div>
          <br />
          <v-form v-model="valid">
            <v-text-field
              v-model="userProfile.displayName"
              :rules="nameRules"
              :counter="28"
              label="Ton pseudo (requis *)"
              required
              @change="updateProfile"
            />
            <v-text-field
              v-model="userProfile.firstName"
              :rules="nameRules"
              :counter="28"
              label="Prénom"
              @change="updateProfile"
            />
            <v-text-field
              v-model="userProfile.lastName"
              :rules="nameRules"
              :counter="28"
              label="Nom"
              @change="updateProfile"
            />
            <v-text-field
              v-model="userProfile.email"
              :rules="emailRules"
              :counter="28"
              label="Email"
              disabled
            />
            <v-text-field
              v-model="userProfile.url"
              :rules="urlRules"
              label="Une URL vers ton site, ton Git (...)"
              @change="updateProfile"
            />
            <v-textarea
              auto-grow
              v-model="userProfile.bio"
              :rules="bioRules"
              :counter="256"
              label="Ta Petite Biographie"
              hint="Ta bio ne doit pas dépasser 256 charactères."
              @change="updateProfile"
            />
            <v-switch
              v-model="userProfile.newsletter"
              label="Recevoir les Bige's News"
              @change="updateProfile"
            />
            <v-switch
              v-model="userProfile.isEmail"
              label="J'accepte de recevoir des emails depuis Bige"
              @change="updateProfile"
            />
            <v-btn :disabled="!valid" color="primary" class="mr-4" @click="validate">Enregistrer</v-btn>
          </v-form>

          <div class="pa-2">
            <v-chip class="pa-2 ma-2" v-for="scope in userProfile.scopes" :key="scope">{{ scope }}</v-chip>
          </div>
          <Logout />
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Coins {{ userProfile.coins }}</v-card-title>
            <v-card-text>Bige intégre de la gamification à titre d'exemple si tu veux en savoir plus lis cet article !</v-card-text>
            <v-progress-linear v-model="globalProgress" color="warning" height="10" reactive />
          </v-card>
          <br />
          <v-card>
            <!-- <v-card-title>Tes Badges</v-card-title>
            <v-card-text>Pour obtenir des Coins, remplis tes missions !</v-card-text>-->
            <v-container>
              <div
                no-gutters
                class="d-flex flex-row pa-2"
                v-for="item in gamification"
                :key="item.label"
              >
                <div
                  class="nomin pa-2"
                  style="width:100px; height:80px; min-width:100px;"
                  v-if="userProfile.gamification[item.type]"
                >
                  <v-progress-circular
                    :rotate="360"
                    :size="80"
                    :width="(userProfile.gamification[item.type].level >= item.levels.length)? 1 : 5"
                    :value="(userProfile.gamification[item.type].count.length / item.levels[item.level].objective)*100"
                    :color="(userProfile.gamification[item.type].level >= item.levels.length)? 'success' : 'primary'"
                    style="position:absolute;"
                  >
                    <v-chip
                      v-if="item.levels[userProfile.gamification[item.type].level]"
                      color="primary"
                    >{{ userProfile.gamification[item.type].count.length }} / {{ item.levels[userProfile.gamification[item.type].level].objective }}</v-chip>
                  </v-progress-circular>
                  <img
                    width="70"
                    height="70"
                    :src="item.badge"
                    style="border-radius:100%; margin:5px;"
                  />
                </div>
                <div class="nomin pa-2" v-if="userProfile.gamification[item.type]">
                  <v-card-title class="pa-0">
                    {{ item.label }}&nbsp;&nbsp;
                    <v-chip
                      small
                      style="right:15px; position:absolute;"
                      :color="(userProfile.gamification[item.type].level === item.levels.length)? 'success' : 'warning'"
                    >Level {{ userProfile.gamification[item.type].level }} / {{ item.levels.length }}</v-chip>
                  </v-card-title>
                  <v-card-text
                    v-if="item.levels[userProfile.gamification[item.type].level] && userProfile.gamification[item.type].level < item.levels.length"
                    class="pa-0"
                    v-html="item.levels[userProfile.gamification[item.type].level].description"
                  />
                  <v-card-text
                    v-else
                    class="pa-0"
                  >Félicitation {{ userProfile.displayName }} tu as obtenu ce Badge !</v-card-text>
                </div>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import Logout from "@/components/Login/Logout.vue";

/* import profileBadge from "@/assets/images/gamification/profileBadge.svg";
import readerBadge from "@/assets/images/gamification/readerBadge.svg";
import reactionBadge from "@/assets/images/gamification/reactionBadge.svg";
import commentBadge from "@/assets/images/gamification/commentBadge.svg";
import postitBadge from "@/assets/images/gamification/postitBadge.svg";
import voteBadge from "@/assets/images/gamification/voteBadge.svg";
import todoBadge from "@/assets/images/gamification/todoBadge.svg"; */
// @ is an alias to /src
export default {
  name: "Account",
  components: { Logout },
  computed: {
    userProfile: {
      get: function () {
        return Object.assign({}, this.excepted, this.$store.state.userProfile);
      },
    },
    gamification: {
      // eslint-disable-next-line vue/return-in-computed-property
      get: function () {
        if (this.$store.state.gamificationRules)
          return Object.entries(this.$store.state.gamificationRules).map(
            (entry) => entry[1]
          );
      },
    },
    globalProgress() {
      if (!this.userProfile) return 0;
      if (typeof this.userProfile.gamification === "undefined") return 0;
      // eslint-disable-next-line no-unused-vars
      let tot = 0,
        cur = 0;
      if (this.gamification) {
        for (let game of this.gamification) {
          tot += game.levels[game.levels.length - 1].objective;
        }
        tot += 7; // TODO : add required inputs for profile completion
        for (let prog of Object.keys(this.userProfile.gamification)) {
          cur += this.userProfile.gamification[prog].count.length;
        }
      }
      return tot / cur;
    },
  },
  watch: {
    userProfile() {
      // return this.$store.state.userProfile;
      this.updateProfile();
    },
    gamification() {},
    percent() {},
  },
  mounted() {
    this.updateProfile();
  },
  data() {
    return {
      excepted: {
        displayName: "",
        firstName: "",
        lastName: "",
        email: "",
        bio: "",
        url: "",
        newsletter: false,
        isEmail: false,
        gamification: {
          read: { level: 0, count: [] },
          profile: { level: 0, count: [] },
          postit: { level: 0, count: [] },
          react: { level: 0, count: [] },
          comment: { level: 0, count: [] },
          vote: { level: 0, count: [] },
        },
      },
      progress: 0,
      value: 34,
      valid: false,
      nameRules: [
        (v) =>
          (typeof v !== "undefined" && v.length <= 28) ||
          "Pas plus de 28 charactères Billy",
      ],
      bioRules: [
        (v) =>
          (typeof v !== "undefined" && v.length <= 256) ||
          "Ta biographie ne doit pas excéder 256 caractères",
      ],
      urlRules: [
        (v) =>
          (/(http[s]?:\/\/)?[^\s(["<,>]*\.[^\s[",><]*/.test(v) &&
            v.length > 0) ||
          "Hum... on diras pas unr url Billy",
      ],
      emailRules: [
        (v) => !!v || "E-mail requis",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    validate() {
      this.$store.commit("upateUserProfile", this.userProfile);
    },
    updateProfile() {
      if (!this.userProfile) return false;
      this.progress = 0;
      Object.keys(this.excepted).forEach((key) => {
        if (
          (typeof this.userProfile[key] === "string" &&
            this.userProfile[key] !== "") ||
          (typeof this.userProfile[key] === "boolean" &&
            this.userProfile[key] === true) ||
          key === "gamification"
        )
          this.progress += 100 / Object.keys(this.excepted).length;
      });
    } /* ,
    async load(path) {
      return require("./" + path).default;
    } */,
  },
};
</script>