<template>
  <v-container fluid>
    <div class="my-2">
      <v-btn small color="danger" @click="logout">Me déconnecter</v-btn>
    </div>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
// import "firebase/firestore";

export default {
  name: "Login",
  data: function() {
    return {
      name: "Signin with Google",
      clientId: "C85c5be67be880fc83c8fbc82477a3f44af9abf8c",
      env: "preproduction"
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(
          function() {
            // Sign-out successful.
            document.cookie =
              "userProfile=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            document.cookie =
              "authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            window.location.href = "/";
          }.bind(this)
        )
        // eslint-disable-next-line no-unused-vars
        .catch(function(error) {
          // An error happened.
        });
    }
  }
};
</script>